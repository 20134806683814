import styled from "styled-components";
import { H2, Anchor } from "@sussex/react-kit/elements";

import useCopy from "../hooks/useCopy";
import logo from "../assets/logo.svg";
import illustration from "../assets/therapist-illustration.png";

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  background: ${({ theme }) => theme.colors.primaryBlue};
  margin-bottom: 50px;
  display: flex;
  min-height: 64px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 0 16px;
`;

const Logo = styled.img`
  height: 36px;
`;

const Content = styled.div`
  background-color: white;
  border-radius: 16px 16px 0 0;
  box-shadow: 0px 0px 30px rgba(31, 41, 55, 0.15);
  border-radius: 16px;
  max-width: 800px;
  margin: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 24px;
  gap: 24px;

  ${({ theme }) => theme.breakpoints[">mobile"]} {
    width: 100%;
    margin: 0 auto;
  }
`;

const Img = styled.img`
  width: 78px;
`;

const Title = styled(H2)`
  font-size: 26px;
  line-height: 1.1;
`;

const Body = styled.p`
  margin: 0;
  padding: 0;
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1.5;
`;

const Link = styled(Anchor)`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1.5;
`;

const DisabledView = () => {
  const [title, body, link] = useCopy([
    "match.discontinued.title",
    "match.discontinued.body",
    "match.discontinued.link",
  ]);

  return (
    <Wrapper>
      <Header>
        <Logo src={logo} alt="Psychology Today" />
      </Header>
      <Content>
        <Img src={illustration} />
        <Title>{title}</Title>
        <Body>{body}</Body>
        <Link href="https://www.psychologytoday.com/us/therapists">{link}</Link>
      </Content>
    </Wrapper>
  );
};

export default DisabledView;
