import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./App";
import PageMetricProvider from "./providers/PageMetricProvider";
import DisabledView from "./pages/DisabledView";

function AppRouter() {
  return (
    <Router>
      <PageMetricProvider />
      <App>
        <Routes>
          <Route path="*" element={<DisabledView />} />
        </Routes>
      </App>
    </Router>
  );
}

export default AppRouter;
